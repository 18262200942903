export const colors = {
	backgroundDarkGrey: '#222222',
	backgroundLightGrey: '#F5F5F5',
	backgroundBlack: '#1F1F1F',
	textBlack: '#1A1A1A',
	textGrey: '#494949',
	textLightGrey: 'rgba(61, 61, 61, 0.7)',
	whiteDimmed: 'rgba(255, 255, 255, 0.5)',
	blackDimmed: 'rgba(0, 0, 0, 0.55)',
	limeGreen: '#AFCA0B',
	errorRed: '#DE1C22',
	white: '#FFFFFF',
	white90: 'rgba(255, 255, 255, 0.9)',
	gray: '#2D2D2C',
};
