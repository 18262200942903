import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../consts/colors';
import FiraSans from '../fonts/Fira_Sans/firaSans';
// @ts-ignore
import cleanIcon from '../images/advantages/clean.svg';
// @ts-ignore
import ambientIcon from '../images/advantages/ambiente.svg';
// @ts-ignore
import personalIcon from '../images/advantages/personaltraining.svg';
// @ts-ignore
import trainingIcon from '../images/advantages/kurse.svg';
// @ts-ignore
import timeIcon from '../images/advantages/fit22_zirkel.svg';
// @ts-ignore
import parkingIcon from '../images/advantages/kostenlose_parkplatze.svg';
// @ts-ignore
import waterMassageIcon from '../images/advantages/wassermassageliege.svg';
// @ts-ignore
import showerIcon from '../images/advantages/duschen.svg';
// @ts-ignore
import roomIcon from '../images/advantages/room.svg';
// @ts-ignore
import coffeeIcon from '../images/advantages/coffee.svg';

const Advantages = () => (
	<Box
		component="section"
		pt={['calc(2.5em + 60px + 80px)', 'calc(2.5em + 60px + 60px)']}
		pb="64px"
		px="16px"
		maxWidth="1200px"
		mx="auto">
		<Typography component="h2" variant="h2" mt="48px" mb="60px" color={colors.textBlack}>
			Warum im EASYFITNESS EMS-Studio trainieren?
		</Typography>

		<Grid container columnSpacing={3} rowSpacing={6} color={colors.textGrey} fontFamily={FiraSans.book}>
			<Grid item xs={12} sm={6} md={4} display="flex" flexDirection="row" alignItems="center">
				<img src={cleanIcon} alt="" loading="lazy" width="76" height="76" />
				<Typography component="span" ml="calc(8px + 5%)" fontFamily="inherit">
					Höchste Hygienestandards
				</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4} display="flex" flexDirection="row" alignItems="center">
				<img src={ambientIcon} alt="" loading="lazy" width="76" height="76" />
				<Typography component="span" ml="calc(8px + 5%)" fontFamily="inherit">
					Exklusives Ambiente
				</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4} display="flex" flexDirection="row" alignItems="center">
				<img src={personalIcon} alt="" loading="lazy" width="76" height="76" />
				<Typography component="span" ml="calc(8px + 5%)" fontFamily="inherit">
					Mit max. 2 Personen im Studio
				</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4} display="flex" flexDirection="row" alignItems="center">
				<img src={trainingIcon} alt="" loading="lazy" width="76" height="76" />
				<Typography component="span" ml="calc(8px + 5%)" fontFamily="inherit">
					Kurze Trainings-Einheiten
				</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4} display="flex" flexDirection="row" alignItems="center">
				<img src={timeIcon} alt="" loading="lazy" width="76" height="76" />
				<Typography
					component="span"
					ml="calc(8px + 5%)"
					fontFamily="inherit"
					whiteSpace={['normal', 'normal', 'pre-line']}>
					Schnelle Erfolge{'\n'}und Zeitersparnis
				</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4} display="flex" flexDirection="row" alignItems="center">
				<img src={parkingIcon} alt="" loading="lazy" width="76" height="76" />
				<Typography component="span" ml="calc(8px + 5%)" fontFamily="inherit">
					Kostenlose Parkplätze
				</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4} display="flex" flexDirection="row" alignItems="center">
				<img src={waterMassageIcon} alt="" loading="lazy" width="76" height="76" />
				<Typography component="span" ml="calc(8px + 5%)" fontFamily="inherit">
					Wassermassageliege
				</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4} display="flex" flexDirection="row" alignItems="center">
				<img src={showerIcon} alt="" loading="lazy" width="76" height="76" />
				<Typography component="span" ml="calc(8px + 5%)" fontFamily="inherit">
					Duschen inklusive
				</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4} display="flex" flexDirection="row" alignItems="center">
				<img src={roomIcon} alt="" loading="lazy" width="76" height="76" />
				<Typography
					component="span"
					ml="calc(8px + 5%)"
					fontFamily="inherit"
					whiteSpace={['normal', 'normal', 'pre-line']}>
					Seperater Trainingsraum{'\n'}für mehr Privatsphäre
				</Typography>
			</Grid>
			<Grid item xs={12} sm={6} md={4} display="flex" flexDirection="row" alignItems="center">
				<img src={coffeeIcon} alt="" loading="lazy" width="76" height="76" />
				<Typography component="span" ml="calc(8px + 5%)" fontFamily="inherit">
					Gratis Kaffebar und Wasserflat
				</Typography>
			</Grid>
		</Grid>
	</Box>
);

export default Advantages;
