import {
	Alert,
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	MobileStepper,
	Radio,
	RadioGroup,
	Slider,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import { css } from '@emotion/react';
import { useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import { colors } from '../consts/colors';
import { Controller, useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
// @ts-ignore
import tickInactive from '../images/tick-inactive.svg';
// @ts-ignore
import tickActive from '../images/tick-active.svg';

enum FormData {
	location = 'location',
	achieve = 'achieve',
	doingSpecific = 'doingSpecific',
	since = 'since',
	importance = 'importance',
	preventing = 'preventing',
	interested = 'interested',
	name = 'name',
	email = 'email',
	phone = 'phone',
	reach = 'reach',
	dataProtection = 'dataProtection',
}

const stepData = [
	FormData.location,
	FormData.achieve,
	FormData.doingSpecific,
	FormData.since,
	FormData.importance,
	FormData.preventing,
	FormData.interested,
	[FormData.name, FormData.email, FormData.phone, FormData.reach, FormData.dataProtection],
];

interface FormValues {
	location: string | null;
	achieve: string | null;
	doingSpecific: string | null;
	since: string;
	importance: number;
	preventing: string | null;
	interested: string | null;
	name: string;
	email: string;
	phone: string;
	reach: string;
	dataProtection: boolean;
}

interface Props {
	show: boolean;
}

const errorMessage = 'Bitte ausfüllen';

const RequestPlaceSteps = ({ show }: Props) => {
	const { control, handleSubmit, trigger } = useForm<FormValues>({
		defaultValues: {
			[FormData.location]: null,
			[FormData.achieve]: null,
			[FormData.doingSpecific]: null,
			[FormData.since]: '',
			[FormData.importance]: 0,
			[FormData.preventing]: null,
			[FormData.interested]: null,
			[FormData.name]: '',
			[FormData.email]: '',
			[FormData.phone]: '',
			[FormData.reach]: '',
			[FormData.dataProtection]: false,
		},
	});

	const [activeStep, setActiveStep] = useState(0);
	const [snackbarOpen, setSnackbarOpen] = useState(false);

	const radio = useMemo(
		() => (
			<Radio
				icon={<img src={tickInactive} alt="" loading="lazy" width="24" height="24" />}
				checkedIcon={<img src={tickActive} alt="" loading="lazy" width="24" height="24" />}
			/>
		),
		[]
	);

	const handlePrevious = useCallback(() => {
		setActiveStep((prev) => prev - 1);
	}, []);

	const handleNext = useCallback(async () => {
		const valid = await trigger(stepData[activeStep]);
		valid && setActiveStep((prev) => prev + 1);
	}, [activeStep]);

	const sendData = useCallback((values: FormValues) => {
		axios
			.request({
				url: process.env.GATSBY_API_URL,
				method: 'POST',
				data: {
					jsonrpc: '2.0',
					id: uuidv4(),
					method: 'RequestPlace',
					params: { message: values },
				},
			})
			.then((response) => {
				if (response.data?.error) {
					setSnackbarOpen(true);
				} else {
					navigate('/danke');
				}
			})
			.catch(() => {
				setSnackbarOpen(true);
			});
	}, []);

	const validateBoolean = useCallback((value: boolean) => {
		return value ? true : errorMessage;
	}, []);

	const handleSnackbarClose = useCallback(() => {
		setSnackbarOpen(false);
	}, []);

	if (!show) {
		return null;
	}

	return (
		<Box component="form" mb="32px" onSubmit={handleSubmit(sendData)}>
			<Box component="section" display={activeStep === 0 ? 'block' : 'none'} minHeight="calc(20.745rem + 27px)">
				<Typography component="h2" variant="h5" textAlign="left" mb="16px" css={headerStyles}>
					Welcher Trainingsort ist dir lieber?
				</Typography>

				<Controller
					render={({ field, fieldState: { error } }) => (
						<FormControl>
							<RadioGroup {...field}>
								<FormControlLabel value="Travemünde" control={radio} label="Travemünde" />
								<FormControlLabel value="Lübeck-Altstadt" control={radio} label="Lübeck-Altstadt" />
								<FormControlLabel value="Stockelsdorf" control={radio} label="Stockelsdorf" />
							</RadioGroup>
							<FormHelperText error={Boolean(error)}>{error?.message || ' '}</FormHelperText>
						</FormControl>
					)}
					name={FormData.location}
					control={control}
					rules={{ required: errorMessage }}
				/>
			</Box>

			<Box component="section" display={activeStep === 1 ? 'block' : 'none'} minHeight="calc(20.745rem + 27px)">
				<Typography component="h2" variant="h5" textAlign="left" mb="8px" css={headerStyles}>
					Was möchtest du gerne erreichen?
				</Typography>
				<Typography
					component="p"
					color={colors.textGrey}
					fontFamily={FiraSans.book}
					fontSize={['1rem', '1.25rem']}
					mb="16px">
					Bitte nenne das Ziel, welches dir am wichtigsten ist
				</Typography>

				<Controller
					render={({ field, fieldState: { error } }) => (
						<FormControl>
							<RadioGroup {...field}>
								<FormControlLabel value="Abnehmen" control={radio} label="Abnehmen" />
								<FormControlLabel
									value="Körper Straffen oder Definieren"
									control={radio}
									label="Körper Straffen oder Definieren"
								/>
								<FormControlLabel value="Muskeln aufbauen" control={radio} label="Muskeln aufbauen" />
								<FormControlLabel value="Rücken stärken" control={radio} label="Rücken stärken" />
								<FormControlLabel value="Fitter werden" control={radio} label="Fitter werden" />
								<FormControlLabel value="Anderes" control={radio} label="Anderes" />
							</RadioGroup>
							<FormHelperText error={Boolean(error)}>{error?.message || ' '}</FormHelperText>
						</FormControl>
					)}
					name={FormData.achieve}
					control={control}
					rules={{ required: errorMessage }}
				/>
			</Box>

			<Box component="section" display={activeStep === 2 ? 'block' : 'none'} minHeight="calc(20.745rem + 27px)">
				<Typography component="h2" variant="h5" textAlign="left" mb="16px" css={headerStyles}>
					Tust du jetzt gerade schon konkret etwas, um deinem Ziel näher zu kommen?
				</Typography>

				<Controller
					render={({ field, fieldState: { error } }) => (
						<FormControl>
							<RadioGroup {...field}>
								<FormControlLabel value="Ja" control={radio} label="Ja" />
								<FormControlLabel value="Nein" control={radio} label="Nein" />
							</RadioGroup>
							<FormHelperText error={Boolean(error)}>{error?.message || ' '}</FormHelperText>
						</FormControl>
					)}
					name={FormData.doingSpecific}
					control={control}
					rules={{ required: errorMessage }}
				/>
			</Box>

			<Box component="section" display={activeStep === 3 ? 'block' : 'none'} minHeight="calc(20.745rem + 27px)">
				<Typography component="h2" variant="h5" textAlign="left" mb="16px" css={headerStyles}>
					Seit wann möchtest du dein Ziel erreichen?
				</Typography>

				<Controller
					render={({ field, fieldState: { error } }) => (
						<TextField
							{...field}
							fullWidth
							variant="standard"
							placeholder="Gib hier Deine Antwort ein..."
							type="text"
							error={Boolean(error)}
							helperText={error?.message || ' '}
						/>
					)}
					name={FormData.since}
					control={control}
					rules={{ required: errorMessage }}
				/>
			</Box>

			<Box component="section" display={activeStep === 4 ? 'block' : 'none'} minHeight="calc(20.745rem + 27px)">
				<Typography component="h2" variant="h5" textAlign="left" mb="8px" css={headerStyles}>
					Auf einer Skala von 0 bis 10, wie wichtig ist es dir, dein Ziel zu erreichen?
				</Typography>
				<Typography
					component="p"
					color={colors.textGrey}
					fontFamily={FiraSans.book}
					fontSize={['1rem', '1.25rem']}>
					0 = "kaum wichtig"
				</Typography>
				<Typography
					component="p"
					color={colors.textGrey}
					fontFamily={FiraSans.book}
					fontSize={['1rem', '1.25rem']}
					mb="48px">
					10 = "sehr wichtig so schnell wie möglich"
				</Typography>

				<Controller
					render={({ field, fieldState: { error } }) => (
						<FormControl fullWidth>
							<Slider
								{...field}
								min={0}
								max={10}
								step={1}
								valueLabelDisplay="auto"
								marks
								color="primary"
							/>
							<FormHelperText error={Boolean(error)}>{error?.message || ' '}</FormHelperText>
						</FormControl>
					)}
					name={FormData.importance}
					control={control}
					rules={{ required: errorMessage }}
				/>
			</Box>

			<Box component="section" display={activeStep === 5 ? 'block' : 'none'} minHeight="calc(20.745rem + 27px)">
				<Typography component="h2" variant="h5" textAlign="left" mb="16px" css={headerStyles}>
					Was hindert dich aktuell am meisten dabei, dein Ziel zu erreichen?
				</Typography>

				<Controller
					render={({ field, fieldState: { error } }) => (
						<FormControl>
							<RadioGroup {...field}>
								<FormControlLabel
									value="Nichts, ich habs fast geschafft"
									control={radio}
									label="Nichts, ich habs fast geschafft"
								/>
								<FormControlLabel
									value="Ich habe wenig Zeit"
									control={radio}
									label="Ich habe wenig Zeit"
								/>
								<FormControlLabel
									value="Ich schaffe es nicht, mich regelmäßig zu motivieren"
									control={radio}
									label="Ich schaffe es nicht, mich regelmäßig zu motivieren"
								/>
								<FormControlLabel
									value="Ich weiß nicht genau, welche Sportart oder Aktivität mir dabei hilft"
									control={radio}
									label="Ich weiß nicht genau, welche Sportart oder Aktivität mir dabei hilft"
								/>
								<FormControlLabel
									value="Ich fühle mich allein und weiß nicht wo ich anfangen soll"
									control={radio}
									label="Ich fühle mich allein und weiß nicht wo ich anfangen soll"
								/>
								<FormControlLabel value="Sonstiges" control={radio} label="Sonstiges" />
							</RadioGroup>
							<FormHelperText error={Boolean(error)}>{error?.message || ' '}</FormHelperText>
						</FormControl>
					)}
					name={FormData.preventing}
					control={control}
					rules={{ required: errorMessage }}
				/>
			</Box>

			<Box component="section" display={activeStep === 6 ? 'block' : 'none'} minHeight="calc(20.745rem + 27px)">
				<Typography
					component="p"
					color={colors.textGrey}
					fontFamily={FiraSans.book}
					fontSize={['1rem', '1.25rem']}
					mb="16px">
					Wenn man{' '}
					<Typography component="strong" fontSize="inherit" fontFamily={FiraSans.medium}>
						dein Ziel
					</Typography>{' '}
					mit EMS Training in nur ein bis zwei kurzen Trainingseinheiten die Woche erreichen könnte,
				</Typography>

				<Typography
					component="p"
					color={colors.textGrey}
					fontFamily={FiraSans.book}
					fontSize={['1rem', '1.25rem']}
					mb="16px">
					bei denen dich ein{' '}
					<Typography component="strong" fontSize="inherit" fontFamily={FiraSans.medium}>
						Personal Trainer
					</Typography>{' '}
					begleitet um besonders schnelle Erfolge zu erzielen,
				</Typography>

				<Typography
					component="h2"
					variant="h5"
					fontFamily={FiraSans.book}
					textAlign="left"
					mb="16px"
					css={headerStyles}>
					Wäre das interessant für dich?
				</Typography>

				<Controller
					render={({ field, fieldState: { error } }) => (
						<FormControl>
							<RadioGroup {...field}>
								<FormControlLabel value="Ja" control={radio} label="Ja" />
								<FormControlLabel value="Nein" control={radio} label="Nein" />
							</RadioGroup>
							<FormHelperText error={Boolean(error)}>{error?.message || ' '}</FormHelperText>
						</FormControl>
					)}
					name={FormData.interested}
					control={control}
					rules={{ required: errorMessage }}
				/>
			</Box>

			<Box component="section" display={activeStep === 7 ? 'block' : 'none'} minHeight="calc(20.745rem + 27px)">
				<Typography component="h2" variant="h5" textAlign="left" mb="16px" css={headerStyles}>
					Informationen
				</Typography>

				<Grid container spacing={4} mb="32px">
					<Grid item xs={12} sm={6}>
						<Controller
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									fullWidth
									variant="standard"
									label="Dein Name"
									placeholder="Wie lautet dein Name?"
									type="text"
									error={Boolean(error)}
									helperText={error?.message || ' '}
								/>
							)}
							name={FormData.name}
							control={control}
							rules={{ required: errorMessage }}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<Controller
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									fullWidth
									variant="standard"
									label="Deine E-Mail Adresse"
									placeholder="Wie lautet dein E-Mail?"
									type="email"
									error={Boolean(error)}
									helperText={error?.message || ' '}
								/>
							)}
							name={FormData.email}
							control={control}
							rules={{ required: errorMessage }}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<Controller
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									fullWidth
									variant="standard"
									label="Deine Rufnummer"
									placeholder="Wie lautet dein Rufnummer?"
									type="tel"
									error={Boolean(error)}
									helperText={error?.message || ' '}
								/>
							)}
							name={FormData.phone}
							control={control}
							rules={{ required: errorMessage }}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<Controller
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									fullWidth
									variant="standard"
									label="Wann bist du am besten erreichbar?"
									placeholder="Gib hier Deine Antwort ein..."
									type="text"
									error={Boolean(error)}
									helperText={error?.message || ' '}
								/>
							)}
							name={FormData.reach}
							control={control}
							rules={{ required: errorMessage }}
						/>
					</Grid>
				</Grid>

				<Controller
					render={({ field, fieldState: { error } }) => (
						<FormControl>
							<FormControlLabel
								{...field}
								control={<Checkbox />}
								label={
									<>
										Ich akzeptiere die{' '}
										<Typography
											component="a"
											href="/datenschutz"
											target="__blank"
											rel="noopener"
											fontFamily="inherit"
											css={linkStyles}>
											Datenschutzerklärung
										</Typography>
										. Ich stimme zu, dass mich das Studio zu Informationszwecken und zur
										Terminvereinbarung kontaktieren darf.
									</>
								}
							/>
							<FormHelperText error={Boolean(error)}>{error?.message || ' '}</FormHelperText>
						</FormControl>
					)}
					name={FormData.dataProtection}
					control={control}
					rules={{ validate: validateBoolean }}
				/>
			</Box>

			<Box mt="32px">
				<MobileStepper
					variant="text"
					steps={8}
					activeStep={activeStep}
					backButton={
						<Button
							variant="outlined"
							color="primary"
							disabled={activeStep === 0}
							onClick={handlePrevious}
							css={outlindedButtonStyles}>
							Zurück
						</Button>
					}
					nextButton={
						activeStep === 7 ? (
							<Button
								onClick={handleSubmit(sendData)}
								variant="contained"
								color="primary"
								css={buttonStyles}>
								Senden
							</Button>
						) : (
							<Button variant="contained" color="primary" onClick={handleNext} css={buttonStyles}>
								Weiter
							</Button>
						)
					}
				/>
			</Box>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={5000}
				onClose={handleSnackbarClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
				<Alert onClose={handleSnackbarClose} severity="error" variant="filled">
					Es ist ein Fehler aufgetreten. Bitte versuchen Sie es in ein paar Sekunden erneut.
				</Alert>
			</Snackbar>
		</Box>
	);
};

export default RequestPlaceSteps;

const headerStyles = css({
	'&:after': {
		content: '" *"',
	},
});

const buttonStyles = css({
	padding: '10px 24px',
});

const outlindedButtonStyles = css({
	padding: '9px 26px',
});

const linkStyles = css({
	textDecoration: 'underline',
});
