import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { colors } from '../consts/colors';
import FiraSans from '../fonts/Fira_Sans/firaSans';
// @ts-ignore
import trianglesImg from '../images/triangles_group.svg';
import { StaticImage } from 'gatsby-plugin-image';

const Quality = () => (
	<Box component="section" pt="80px" pb={['80px', '120px']}>
		<img src={trianglesImg} alt="" loading="lazy" width="729" height="921" css={trianglesImgStyles} />

		<Box
			display="flex"
			flexDirection="row"
			justifyContent="center"
			alignItems="center"
			borderRadius="16px"
			marginBottom="32px"
			gap="32px">
			<StaticImage
				src="../images/photo-d.jpg"
				alt=""
				loading="lazy"
				layout="constrained"
				placeholder="blurred"
				objectFit="cover"
				objectPosition="center center"
				css={imageStyles}
			/>
			<StaticImage
				src="../images/photo-e.jpg"
				alt=""
				loading="lazy"
				layout="constrained"
				placeholder="blurred"
				objectFit="cover"
				objectPosition="center center"
				css={imageStyles}
			/>
		</Box>

		<Typography component="h2" variant="h2" mb="32px">
			Unser Qualitätsversprechen
		</Typography>

		<Grid container spacing={4} mb="48px" pr="32px" maxWidth="1200px" mx="auto" width="100%">
			<Grid item xs={12} md={4}>
				<Box
					height="100%"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					textAlign="center"
					borderRadius="16px">
					<Card elevation={2}>
						<CardContent>
							<Typography component="h3" variant="h3" color={colors.textBlack} mb="24px">
								PROFESSIONELLE EINWEISUNG
							</Typography>
							<Typography component="p" color={colors.textGrey} fontFamily={FiraSans.book}>
								Unsere ausgebildeten Trainer nehmen sich alle Zeit, um dich mit EMS-Training vertraut zu
								machen. Du erhältst eine individuelle Beratung und Zielanalyse.
							</Typography>
						</CardContent>
					</Card>
				</Box>
			</Grid>
			<Grid item xs={12} md={4}>
				<Box
					height="100%"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					textAlign="center"
					borderRadius="16px">
					<Card elevation={2}>
						<CardContent>
							<Typography
								component="h3"
								variant="h3"
								color={colors.textBlack}
								mb="24px"
								whiteSpace={['normal', 'normal', 'pre-line']}>
								ZEIT{'\n'}SPAREN
							</Typography>
							<Typography component="p" color={colors.textGrey} fontFamily={FiraSans.book}>
								EMS-Training dauert nur 20 Minuten die Woche und ist trotzdem so effektiv wie ca. 1,5
								Stunden im Fitnessstudio. Optimal für klasse Erfolge mit wenig Zeitaufwand!
							</Typography>
						</CardContent>
					</Card>
				</Box>
			</Grid>
			<Grid item xs={12} md={4}>
				<Box
					height="100%"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					textAlign="center"
					borderRadius="16px">
					<Card elevation={2}>
						<CardContent>
							<Typography component="h3" variant="h3" color={colors.textBlack} mb="24px">
								PERSÖNLICHE BETREUUNG
							</Typography>
							<Typography component="p" color={colors.textGrey} fontFamily={FiraSans.book}>
								Du wirst während jeder Trainingseinheit persönlich von einem unserer Trainer betreut.
								Jedes Training wird perfekt auf dich und deine Bedürfnisse abgestimmt.
							</Typography>
						</CardContent>
					</Card>
				</Box>
			</Grid>
		</Grid>

		<Box mt="60px" px="16px" textAlign="center">
			<Typography component="h2" variant="h4" mb="24px">
				Erfahre jetzt alles über das EMS-Programm!
			</Typography>

			{/* @ts-ignore */}
			<Link to="/platz-anfragen" css={buttonStyle}>
				Hier Platz anfragen
			</Link>
		</Box>
	</Box>
);

export default Quality;

const imageStyles = css({
	borderRadius: '16px',
	height: '400px',
	width: '270px',
});
// @ts-ignore
const trianglesImgStyles = css({
	position: 'absolute !important',
	zIndex: -1,
	maxWidth: '100%',
});

const buttonStyle = css({
	display: 'inline-block',
	color: colors.textBlack,
	backgroundColor: colors.limeGreen,
	padding: '14px 34px',
	borderRadius: '8px',
	fontFamily: FiraSans.medium,
	fontSize: '1rem',
	lineHeight: 1.5,
});
