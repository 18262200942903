import { Box, Grid, Typography } from '@mui/material';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { colors } from '../consts/colors';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import ImageDisclaimer from './imageDisclaimer';

const Special = () => (
	<Box component="section" pb={['80px', '120px']}>
		<Grid container spacing={4} pr="32px" maxWidth="1200px" mx="auto" width="100%">
			<Grid item xs={12} md={4}>
				<ImageDisclaimer>
					<StaticImage
						src="../images/photo-c.jpg"
						alt=""
						loading="lazy"
						layout="constrained"
						placeholder="blurred"
						objectFit="cover"
						objectPosition="center center"
						css={imageStyles}
					/>
				</ImageDisclaimer>
			</Grid>
			<p></p>
			<Grid
				item
				xs={12}
				md={8}
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
				alignItems="flex-start"
				width="100%">
				<Box color={colors.textGrey} fontFamily={FiraSans.book}>
					<Typography component="p" fontFamily="inherit">
						Wir bei EASYFITNESS EMS achten bei unseren Kunden insbesondere darauf, dass sie die Übungen auch
						korrekt ausführen. Denn wer kennt es nicht? Man macht seine Übung im Fitnessstudio vielleicht
						Mal doch nicht so, wie es der Trainer einem mal gezeigt hat. Das passiert dir bei uns nie! Bei
						jedem Training steht dir ein Personal Trainer zur Seite, der dir auf dich zugeschnittene Übungen
						zeigt, dich gegebenenfalls korrigiert und motiviert.
					</Typography>
					<Typography component="p" fontFamily="inherit">
						Außerdem kombinieren wir EMS-Impulse mit funktionellen Training. Diese Mischung ist ein
						Erfolgsrezept. Neben der hocheffektiven Muskelwirkung von EMS trainieren wir gleichzeitig Deine
						Koordination und Deine alltagstaugliche Motorik trainiert.
					</Typography>
				</Box>

				{/* @ts-ignore */}
				<Link to="/platz-anfragen" css={buttonStyle}>
					Hier Platz anfragen
				</Link>
			</Grid>
		</Grid>
	</Box>
);

export default Special;

const imageStyles = css({
	width: '100%',
	borderRadius: '16px',
});

const buttonStyle = css({
	display: 'inline-block',
	color: colors.textBlack,
	backgroundColor: colors.limeGreen,
	padding: '14px 34px',
	borderRadius: '8px',
	fontFamily: FiraSans.medium,
	fontSize: '1rem',
	lineHeight: 1.5,
	marginTop: '32px',
});
