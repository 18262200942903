import { Dialog, DialogActions, DialogContent, DialogTitle, Box } from '@mui/material';
import { css } from '@emotion/react';
import React, { ForwardedRef, forwardRef } from 'react';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import Cookies from 'js-cookie';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import { colors } from '../consts/colors';

interface Props {
	areCookiesAccepted: boolean;
	setAreCookiesAccepted: (value: boolean) => void;
}

export interface CookieModalProps {
	openDialog: () => void;
}

const CookieModal = (props: Props, ref: ForwardedRef<CookieModalProps>) => {
	const { areCookiesAccepted, setAreCookiesAccepted } = props;

	const [open, setOpen] = React.useState(false);

	const location = useLocation();

	const openDialog = React.useCallback(() => {
		setOpen(true);
	}, []);

	const closeDialog = React.useCallback(() => {
		setOpen(false);
	}, []);

	React.useImperativeHandle(ref, () => {
		return {
			openDialog: openDialog,
		};
	});

	const handleReject = React.useCallback(() => {
		setAreCookiesAccepted(true);
		Cookies.set('gatsby-gdpr-google-analytics', 'false');
		Cookies.set('gatsby-gdpr-google-tagmanager', 'false');
		Cookies.set('gatsby-gdpr-facebook-pixel', 'false');
		closeDialog();
	}, []);

	const handleAccept = React.useCallback(() => {
		Cookies.set('gatsby-gdpr-google-analytics', 'true');
		Cookies.set('gatsby-gdpr-google-tagmanager', 'true');
		Cookies.set('gatsby-gdpr-facebook-pixel', 'true');
		initializeAndTrack(location);
		closeDialog();
	}, [location]);

	return (
		<Dialog open={open && !areCookiesAccepted} onClose={closeDialog} maxWidth={'md'}>
			<DialogTitle>Wir respektieren Ihre Privatsphäre.</DialogTitle>
			<DialogContent>
				Wir und bestimmte Dritte verwenden Cookies. Einzelheiten zu den Arten von Cookies, ihrem Zweck und den
				beteiligten Stellen finden Sie in unserer Datenschutzerklärung. Bitte willigen Sie in die Verwendung von
				Cookies ein, indem Sie auf „OK, weiter!“ klicken, um die bestmögliche Nutzererfahrung auf unseren
				Webseiten zu haben.
			</DialogContent>
			<DialogActions>
				<Box
					component="a"
					href="https://easyfitness.club/datenschutzerklaerung/"
					target="_blank"
					fontFamily={FiraSans.regular}
					fontSize="0.75em"
					lineHeight="1.3333333333333333em"
					color={colors.textGrey}
					css={privacyLink}>
					Datenschutzerklärung
				</Box>
				<Box
					component="button"
					onClick={handleReject}
					fontFamily={FiraSans.regular}
					fontSize="0.75em"
					lineHeight="1.3333333333333333em"
					color={colors.textGrey}
					border={0}
					bgcolor="unset"
					p={0}
					css={rejectButton}>
					Weiter ohne Verwendung von Cookies
				</Box>
				<Box
					component="button"
					onClick={handleAccept}
					fontFamily={FiraSans.medium}
					fontSize="1rem"
					lineHeight="1.5em"
					color="black"
					border={0}
					borderRadius="4px"
					padding="0.75em 2.25em"
					css={acceptButton}
					bgcolor={colors.limeGreen}>
					OK, weiter!
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default forwardRef(CookieModal);

const privacyLink = css({
	textDecoration: 'underline',
});

const rejectButton = css({
	textDecoration: 'underline',
	outline: 0,
	cursor: 'pointer',
});

const acceptButton = css({
	outline: 0,
	cursor: 'pointer',
});
