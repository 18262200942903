import { Box, Grid, Typography } from '@mui/material';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { colors } from '../consts/colors';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import ImageDisclaimer from './imageDisclaimer';

const HowItWorks = () => (
	<Box component="section" py="32px">
		<Typography component="h2" variant="h2" mb="40px" px="16px" color={colors.textBlack} css={headerStyles}>
			Programm mit EMS-Training, so gehts
		</Typography>

		<Typography component="h3" variant="h3" mb="24px" px="16px" color={colors.textBlack}>
			Was genau ist EMS-Training eigentlich?
		</Typography>
		<Grid container spacing={4} pr="32px" maxWidth="1200px" mx="auto" width="100%">
			<Grid item xs={12} md={4}>
				<ImageDisclaimer>
					<StaticImage
						src="../images/photo-a.jpg"
						alt=""
						loading="lazy"
						layout="constrained"
						placeholder="blurred"
						objectFit="cover"
						objectPosition="center center"
						css={imageStyles}
					/>
				</ImageDisclaimer>
			</Grid>
			<Grid
				item
				xs={12}
				md={8}
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
				alignItems="flex-start"
				width="100%">
				<Box color={colors.textGrey} fontFamily={FiraSans.book}>
					<Typography component="p" fontFamily="inherit">
						EMS Training ist eine äußerst wirksame Trainingsmethode, um etwas für seine Figur zu tun.
					</Typography>
					<Typography component="p" fontFamily="inherit">
						Über 1.300 EMS Studios gibt es bereits in Deutschland, Österreich und der Schweiz und es kommen
						jede Woche neue dazu. Mittels sanfter, elektronischer Impulse werden alle großen Muskelgruppen
						am Körper stimuliert und zur Kalorienverbrennung angeregt. Gerade die kurze Trainingsdauer und
						der schnelle Trainingseffekt begeistern die Trainierenden. Zusätzlich sorgt die angeregte
						Muskulatur für eine ausgeprägtere und definierte Muskulatur.
					</Typography>
					<Typography component="p" fontFamily="inherit">
						Jede Einheit wird von einem ausgebildeten Personal Trainer begleitet, der mit dir gemeinsam das
						Training entsprechend deiner Wünsche anpasst und den Effekt an den von dir gewünschten
						Körperpartien besonders in den Fokus stellt.
					</Typography>
				</Box>

				{/* @ts-ignore */}
				<Link to="/platz-anfragen" css={buttonStyle}>
					Hier Platz anfragen
				</Link>
			</Grid>
		</Grid>

		<Box bgcolor={colors.backgroundBlack} color="white" px="32px" py="48px" my="64px">
			<Typography component="p" maxWidth="820px" mx="auto" px="16px" fontFamily={FiraSans.medium}>
				Eine EMS Trainingseinheit dauert in der Regel nur 20 Minuten und ist in etwa so effektiv wie 1,5 Stunden
				im herkömmlichen Fitnessstudio. Dein regelmäßiger Termin hilft dir dabei, besonders schnell Erfolge zu
				erzielen.
			</Typography>
		</Box>

		<Typography component="h3" variant="h3" mb="24px" px="16px" color={colors.textBlack}>
			Kann man mit EMS-Training seine Figur verbessern?
		</Typography>
		<Grid container spacing={4} pr="32px" maxWidth="1200px" mx="auto" width="100%">
			<Grid
				item
				xs={12}
				md={8}
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
				alignItems="flex-start"
				width="100%"
				order={[2, 2, 1]}>
				<Box color={colors.textGrey} fontFamily={FiraSans.book}>
					<Typography component="p" fontFamily="inherit">
						Ja, mit EMS-Training kann sowohl eine definierte Muskulatur als auch eine erhöhte
						Fettverbrennung erzielt werden.
					</Typography>
					<Typography component="p" fontFamily="inherit">
						Die sanften Impulse sprechen fast doppelt so viele Muskelfasern an als es herkömmliches
						Fitnesstraining schafft.
					</Typography>
					<Typography component="p" fontFamily="inherit">
						Somit lassen sich in besonders kurzer Zeit sichtbare Erfolge erzielen.
					</Typography>
					<Typography component="p" fontFamily="inherit">
						Gleichzeitig hilft der Personal Trainer dir bei jeder Trainingseinheit, deine Wunschzonen
						besonders intensiv anusprechen um dort bessere Effekte zu erzielen. Ob Arme, Brust, Rücken oder
						Bauch, es werden tatsächlich alle Körperpartien in nur 20 Minuten trainiert.
					</Typography>
					<Typography component="p" fontFamily="inherit">
						Durch den tiefen Impuls lassen sich Effekte besonders schnell wahrnehmen.
					</Typography>
				</Box>

				{/* @ts-ignore */}
				<Link to="/platz-anfragen" css={buttonStyle}>
					Hier Platz anfragen
				</Link>
			</Grid>
			<Grid item xs={12} md={4} order={[1, 1, 2]}>
				<ImageDisclaimer>
					<StaticImage
						src="../images/photo-b.jpg"
						alt=""
						loading="lazy"
						layout="constrained"
						placeholder="blurred"
						objectFit="cover"
						objectPosition="center top"
						css={imageStyles}
					/>
				</ImageDisclaimer>
			</Grid>
		</Grid>
	</Box>
);

export default HowItWorks;

const headerStyles = css({
	'&::after': {
		content: '":"',
	},
});

const imageStyles = css({
	width: '100%',
	borderRadius: '16px',
});

const buttonStyle = css({
	display: 'inline-block',
	color: colors.textBlack,
	backgroundColor: colors.limeGreen,
	padding: '14px 34px',
	borderRadius: '8px',
	fontFamily: FiraSans.medium,
	fontSize: '1rem',
	lineHeight: 1.5,
	marginTop: '32px',
});
