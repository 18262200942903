import { Box, Theme, Typography, useTheme } from '@mui/material';
import { css } from '@emotion/react';
import { Link } from 'gatsby';

import FiraSans from '../fonts/Fira_Sans/firaSans';
import { colors } from '../consts/colors';
import { StaticImage } from 'gatsby-plugin-image';

const Banner = () => {
	const theme = useTheme();

	return (
		<Box
			component="section"
			position="relative"
			height="calc(100vh - 122px)"
			minHeight={['630px', '525px']}
			color="white"
			bgcolor="black"
			textAlign="center">
			<StaticImage
				alt="Ausbildung"
				src="../images/banner.jpg"
				loading="eager"
				layout="constrained"
				placeholder="blurred"
				css={backgroundStyle}
			/>

			<Box
				position="relative"
				maxWidth="880px"
				height="calc(100% - 2.5em - 60px)"
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				mx="auto"
				px="48px"
				py="32px"
				boxSizing="border-box">
				<Typography component="h1" variant="h1" mb="32px">
					NEU! JETZT 3x IN LÜBECK
				</Typography>
				<Typography component="p" fontSize={['1rem', '1.5rem']} lineHeight={1.5} fontFamily={FiraSans.regular}>
					Jetzt einen von 27 Plätzen für die limitierte Challenge reservieren. Werde Teil der Green-Family und
					trainiere mit Personaltrainer im exclusiven EASYFITNESS EMS Premium Club. Mit Minimalaufwand zur
					Wunschfigur und einem Leben ohne Beschwerden!
				</Typography>
			</Box>

			<Box
				component="div"
				position="absolute"
				left={['0%', '50%']}
				bottom={0}
				bgcolor={colors.textBlack}
				borderTop={`12px solid ${colors.limeGreen}`}
				borderBottom={`12px solid ${colors.limeGreen}`}
				py={['72px', '48px']}
				width={['100%', '30em', '40em']}
				overflow="hidden"
				css={callToActionContainerStyles(theme)}>
				<Box css={callToActionInnerBoxStyles(theme)}>
					<Typography component="h2" variant="h2" mb="28px">
						Investiere in Deine Gesundheit!
					</Typography>
					{/* @ts-ignore */}
					<Link to="/platz-anfragen" css={buttonStyle}>
						Hier Platz anfragen
					</Link>
				</Box>
			</Box>
		</Box>
	);
};

export default Banner;

// @ts-ignore
const backgroundStyle = css({
	height: '100%',
	width: '100%',
	position: 'absolute !important',
	objectFit: 'cover',
	left: '0',
	opacity: '0.6',
});

const callToActionContainerStyles = (theme: Theme) =>
	css({
		transform: 'translateY(50%) skewY(-2deg)',
		[theme.breakpoints.up('sm')]: {
			transform: 'translate(-50%, 50%) skewX(20deg)',
		},
	});

const callToActionInnerBoxStyles = (theme: Theme) =>
	css({
		transform: 'skewY(2deg)',
		[theme.breakpoints.up('sm')]: {
			transform: 'skewX(-20deg)',
		},
	});

const buttonStyle = css({
	display: 'inline-block',
	color: colors.textBlack,
	backgroundColor: colors.limeGreen,
	padding: '14px 34px',
	borderRadius: '8px',
	fontFamily: FiraSans.medium,
	fontSize: '1rem',
	lineHeight: 1.5,
});
