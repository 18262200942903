import { Box, Typography } from '@mui/material';
import { colors } from '../consts/colors';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import Fjalla from '../fonts/Fjalla_One/fjalla';
import { Link } from 'gatsby';

const Footer = () => (
	<Box
		component="footer"
		display="flex"
		flexDirection={['column', 'row']}
		justifyContent="space-between"
		alignItems="center"
		bgcolor={colors.backgroundDarkGrey}
		px="16px"
		py="32px"
		fontSize="0.875rem"
		lineHeight="1.25rem">
		<Typography fontFamily={FiraSans.regular} color={colors.whiteDimmed} order={[2, 1]} mt={['32px', 0]}>
			Copyright &copy; Sports Vision Management GmbH
		</Typography>
		<Box color="white" fontFamily={Fjalla.regular} order={[1, 2]}>
			{/* <Typography
				component="a"
				href="https://easyfitness.club/impressum/"
				target="__blank"
				rel="noopener"
				fontFamily="inherit">
				Impressum
			</Typography> */}
			{/* @ts-ignore */}
			<Link to="/impressum" target="__blank">
				Impressum
			</Link>
			<Typography component="span" mx="16px" fontFamily="inherit">
				&middot;
			</Typography>
			{/* <Typography
				component="a"
				href="https://easyfitness.club/datenschutzerklaerung/"
				target="__blank"
				rel="noopener"
				fontFamily="inherit">
				Datenschutz
			</Typography> */}
			{/* @ts-ignore */}
			<Link to="/datenschutz" target="__blank">
				Datenschutz
			</Link>
		</Box>
	</Box>
);

export default Footer;
