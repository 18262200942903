import { Box, Typography } from '@mui/material';
import { css } from '@emotion/react';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import { StaticImage } from 'gatsby-plugin-image';
import { colors } from '../consts/colors';
import ImageDisclaimer from './imageDisclaimer';

const PremiumStudio = () => (
	<Box
		component="section"
		position="relative"
		py="32px"
		px="16px"
		id="studioanschrift"
		minHeight={['unset', 'unset', '480px', '600px']}>
		<StaticImage
			src="../images/EasyFit6.jpg"
			alt=""
			loading="lazy"
			layout="fullWidth"
			placeholder="blurred"
			css={backgroundImageStyles}
		/>
		<ImageDisclaimer />

		<Typography component="h2" variant="h2" mb={['20px', '40px', '40px', '140px']} px="16px" color="white">
			Dein Premium Studio
		</Typography>

		<Box
			component="address"
			maxWidth="1000px"
			mx="auto"
			px="16px"
			fontStyle="normal"
			color="white"
			fontSize={['1rem', '1.25rem']}
			mb="16px"
			borderLeft={`8px solid ${colors.limeGreen}`}
			display="flex"
			flexDirection="column"
			alignItems="flex-start">
			<Typography component="a" href="tel:+4945028898680" fontSize="inherit" fontFamily={FiraSans.book}>
				Telefon: 04502 8898680
			</Typography>
			<Typography component="a" href="tel:+491749661939" fontSize="inherit" fontFamily={FiraSans.book}>
				Mobil: 0174 9661939
			</Typography>

			<Typography component="p" fontSize="inherit" fontFamily={FiraSans.book} mt="16px">
				EASYFITNESS.club EMS Travemünde
			</Typography>
			<Typography component="p" fontSize="inherit" fontFamily={FiraSans.medium}>
				Kurgartenstraße 118
			</Typography>
			<Typography component="p" fontSize="inherit" fontFamily={FiraSans.medium}>
				23570 Lübeck
			</Typography>
			<Typography
				component="a"
				href="mailto:ems-travemuende@easyfitness.club"
				fontSize="inherit"
				fontFamily={FiraSans.book}>
				ems-travemuende@easyfitness.club
			</Typography>

			<Typography component="p" fontSize="inherit" fontFamily={FiraSans.book} mt="16px">
				EASYFITNESS.Club EMS Lübeck-Altstadt
			</Typography>
			<Typography component="p" fontSize="inherit" fontFamily={FiraSans.medium}>
				Mühlenstraße 71
			</Typography>
			<Typography component="p" fontSize="inherit" fontFamily={FiraSans.medium}>
				23552 Lübeck
			</Typography>
			<Typography
				component="a"
				href="mailto:ems-luebeck@easyfitness.club"
				fontSize="inherit"
				fontFamily={FiraSans.book}>
				ems-luebeck@easyfitness.club
			</Typography>

			<Typography component="p" fontSize="inherit" fontFamily={FiraSans.medium} mt="16px">
				Öffnungszeiten:
			</Typography>
			<Typography component="p" fontSize="inherit" fontFamily={FiraSans.book}>
				Montag bis Freitag 08:00 - 21:00 Uhr
			</Typography>
			<Typography component="p" fontSize="inherit" fontFamily={FiraSans.book}>
				Samstag 10:00 - 18:00 Uhr
			</Typography>
			<Typography component="p" fontSize="inherit" fontFamily={FiraSans.book}>
				Sonntag geschlossen
			</Typography>
		</Box>
	</Box>
);

export default PremiumStudio;

// @ts-ignore
const backgroundImageStyles = css({
	position: 'absolute !important',
	top: 0,
	left: 0,
	zIndex: -1,
	width: '100%',
	height: '100%',
	backgroundColor: 'black',
	'&>picture>img': {
		opacity: '0.8 !important',
	},
});
