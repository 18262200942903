import { StaticImage } from 'gatsby-plugin-image';
import { Box } from '@mui/material';
import { Link } from 'gatsby';
import { useMemo } from 'react';

interface Props {
	logoLink: boolean;
}

const Header = ({ logoLink }: Props) => {
	const logo = useMemo(() => {
		if (logoLink) {
			return (
				// @ts-ignore
				<Link to="/">
					<StaticImage
						src="../images/logo.jpg"
						alt="Easyfitness.club EMS logo"
						loading="eager"
						layout="constrained"
						placeholder="blurred"
					/>
				</Link>
			);
		}
		return (
			<StaticImage
				src="../images/logo.jpg"
				alt="Easyfitness.club EMS logo"
				loading="eager"
				layout="constrained"
				placeholder="blurred"
			/>
		);
	}, [logoLink]);

	return (
		<Box component="header" p="20px" textAlign="center">
			{logo}
		</Box>
	);
};

export default Header;
