import { css } from '@emotion/react';
import { Box, Typography } from '@mui/material';

import FiraSans from '../fonts/Fira_Sans/firaSans';
import { colors } from '../consts/colors';

interface Props {
	children?: JSX.Element;
	maxWidth?: string | number | string[] | number[];
}

const IMAGE_DISCLAMER = '*Profisportler, tatsächliche Ergebnisse können abweichen';

const ImageDisclaimer = ({ children, maxWidth }: Props) => {
	return (
		<>
			{children ? (
				<Box css={imageFrame}>
					{children}
					<Typography css={textStyles} component="p" fontFamily={FiraSans.light}>
						{IMAGE_DISCLAMER}
					</Typography>
				</Box>
			) : (
				<Typography css={textStyles} component="p" fontFamily={FiraSans.light} maxWidth={maxWidth}>
					{IMAGE_DISCLAMER}
				</Typography>
			)}
		</>
	);
};

export default ImageDisclaimer;

const textStyles = css({
	position: 'absolute',
	bottom: '0.3em',
	right: '0.5em',
	color: colors.whiteDimmed,
	fontSize: '12px',
});

const imageFrame = css({
	position: 'relative',
});
