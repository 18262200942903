import { Box, Card, CardContent, Theme, Typography, useTheme } from '@mui/material';
import { colors } from '../consts/colors';
import { css } from '@emotion/react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import FiraSans from '../fonts/Fira_Sans/firaSans';
// @ts-ignore
import arrowLeftIcon from '../images/arrowLeft.svg';
// @ts-ignore
import arrowRightIcon from '../images/arrowRight.svg';
import { GoogleReview } from '../pages/index';

interface Props {
	googleReviews: GoogleReview[];
}

const Reviews = ({ googleReviews }: Props) => {
	const theme = useTheme();

	return (
		<Box
			component="section"
			bgcolor={colors.backgroundBlack}
			color="white"
			pt={['60px', '80px']}
			pb={['80px', '128px']}
			mt="80px"
			css={sectionStyles}>
			<Box css={innerBoxStyles}>
				<Typography component="h2" variant="h2" mb="68px">
					Kundenbewertungen
				</Typography>

				<Carousel
					showStatus={false}
					infiniteLoop={true}
					showIndicators={false}
					showThumbs={false}
					swipeable={true}
					emulateTouch={true}
					centerMode={true}
					dynamicHeight={true}
					centerSlidePercentage={83.33333333}
					labels={{
						item: 'Kundenrezension',
						leftArrow: `Vorherige`,
						rightArrow: `Nächste`,
					}}
					renderArrowPrev={(onClickHandler, hasPrev, label) =>
						hasPrev && (
							<Box title={label} onClick={onClickHandler} role="button" css={arrowLeftStyles}>
								<img src={arrowLeftIcon} alt="" loading="lazy" width="28" height="56" />
							</Box>
						)
					}
					renderArrowNext={(onClickHandler, hasNext, label) =>
						hasNext && (
							<Box title={label} onClick={onClickHandler} role="button" css={arrowRightStyles}>
								<img src={arrowRightIcon} alt="" loading="lazy" width="28" height="56" />
							</Box>
						)
					}>
					{googleReviews.map((review, key) => (
						<Box key={key} px="32px" display="flex">
							<Card css={cardRootStyle}>
								<CardContent>
									<Typography component="blockquote" mb="24px" fontFamily={FiraSans.book}>
										{review.body}
									</Typography>
									{/* <Typography
										component="p"
										fontFamily={FiraSans.semiBold}
										color={colors.textGrey}
										mb="24px">
										Bewertung: {review.rating} / 5
									</Typography> */}
									<Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
										<img
											src={review.sourceImage}
											alt={review.source}
											loading="lazy"
											width={40}
											height={40}
											css={imageStyle(theme)}
										/>
										<Box textAlign="left">
											<Typography component="p" fontFamily={FiraSans.semiBold}>
												{review.source}
											</Typography>
											<Typography
												component="p"
												fontFamily={FiraSans.light}
												color={colors.textGrey}>
												{review.date}
											</Typography>
										</Box>
									</Box>
								</CardContent>
							</Card>
						</Box>
					))}
				</Carousel>
			</Box>
		</Box>
	);
};

export default Reviews;

const sectionStyles = css({
	transform: 'skewY(-3deg)',
});

const innerBoxStyles = css({
	transform: 'skewY(3deg)',
});

const imageStyle = (theme: Theme) =>
	css({
		objectFit: 'cover',
		objectPosition: 'center center',
		borderRadius: '50%',
		marginRight: '12px',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	});

// @ts-ignore
const arrowLeftStyles = css({
	cursor: 'pointer',
	position: 'absolute !important',
	top: '50%',
	left: '16px',
	transform: 'translateY(-50%)',
	zIndex: 2,
});

// @ts-ignore
const arrowRightStyles = css({
	cursor: 'pointer',
	position: 'absolute !important',
	top: '50%',
	right: '16px',
	transform: 'translateY(-50%)',
	zIndex: 2,
});

const cardRootStyle = css({
	width: '100%',
});
