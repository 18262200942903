import { Box, Button, Typography } from '@mui/material';
import { colors } from '../consts/colors';
import FiraSans from '../fonts/Fira_Sans/firaSans';

interface Props {
	hide: boolean;
	handleClick: () => void;
}

const RequestPlaceLanding = ({ hide, handleClick }: Props) => {
	if (hide) {
		return null;
	}

	return (
		<Box component="section" maxWidth="720px" mx="auto" px="16px" py="48px" textAlign="center">
			<Typography component="h1" variant="h1" mb="24px">
				JETZT PLATZ ANFRAGEN
			</Typography>
			<Typography
				component="p"
				fontSize={['1.25rem', '1.5rem']}
				color={colors.textGrey}
				fontFamily={FiraSans.book}
				mb="16px">
				Bitte fülle diesen kurzen Fragebogen aus, damit dem Studio alle wichtigen Informationen vorliegen, um
				dir zu helfen
			</Typography>
			<Typography
				component="p"
				fontSize={['1rem', '1.25rem']}
				color={colors.textLightGrey}
				fontFamily={FiraSans.regular}
				mb="32px">
				Es dauert maximal eine Minute
			</Typography>
			<Button variant="contained" color="primary" onClick={handleClick}>
				Start
			</Button>
		</Box>
	);
};

export default RequestPlaceLanding;
