import { ThemeProvider } from '@mui/material';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import Footer from '../components/footer';
import { mainTheme } from '../consts/themes';
import Banner from '../components/banner';
import Advantages from '../components/advantages';
import Achieve from '../components/achieve';
import HowItWorks from '../components/howItWorks';
import Reviews from '../components/reviews';
import Quality from '../components/quality';
import Special from '../components/special';
import PremiumStudio from '../components/premiumStudio';
import '../components/app.css';
import '../fonts/Fira_Sans/firaSans.css';
import '../fonts/Fjalla_One/fjalla.css';
// @ts-ignore
import ogImage from '../images/EasyFit6.jpg';
import CookieModal, { CookieModalProps } from '../components/cookieModal';
import { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';

export interface GoogleReview {
	body: string;
	// rating: number;
	source: string;
	sourceImage: string;
	date: string;
}

export interface GoogleReviewsResponse {
	allGoogleReview: {
		nodes: GoogleReview[];
	};
}

interface Props {
	data: GoogleReviewsResponse;
	[key: string]: any;
}

export const googleReviewsQuery = graphql`
	query GoogleReviewsIndex {
		allGoogleReview {
			nodes {
				body
				source
				sourceImage
				date
			}
		}
	}
`;

const title = 'EASYFITNESS EMS Premium Club';
const description =
	'Werde Teil der Green-Family und trainiere mit Personaltrainer im exclusiven EASYFITNESS EMS Premium Club. Mit Minimalaufwand zur Wunschfigur und einem Leben ohne Beschwerden!';

const IndexPage = ({ data }: Props) => {
	const [areCookiesAccepted, setAreCookiesAccepted] = useState(true);

	const cookieModalRef = useRef<CookieModalProps>(null);

	useEffect(() => {
		setTimeout(() => {
			setAreCookiesAccepted(Cookies.get('gatsby-gdpr-facebook-pixel') === 'true' ? true : false);
			cookieModalRef.current?.openDialog();
		}, 1000);
	}, []);

	return (
		<ThemeProvider theme={mainTheme}>
			<Helmet htmlAttributes={{ lang: 'de' }}>
				<title>{title}</title>
				<meta property="og:title" content={title} />
				<meta property="og:type" content="website" />
				<meta name="description" property="og:description" content={description} />
				<meta property="og:image" content={ogImage} />
				<meta property="og:image:width" content="1280" />
				<meta property="og:image:height" content="721" />
				<meta name="facebook-domain-verification" content="u8yg6445br7e8s4ze31aszjzih2nay" />
			</Helmet>
			<Header logoLink={false} />
			<main>
				<Banner />
				<Advantages />
				<Achieve />
				<HowItWorks />
				<Reviews googleReviews={data.allGoogleReview.nodes} />
				<Quality />
				<Special />
				<PremiumStudio />
			</main>
			<Footer />
			<CookieModal
				ref={cookieModalRef}
				areCookiesAccepted={areCookiesAccepted}
				setAreCookiesAccepted={setAreCookiesAccepted}
			/>
		</ThemeProvider>
	);
};

export default IndexPage;
