import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import { colors } from '../consts/colors';
import FiraSans from '../fonts/Fira_Sans/firaSans';
// @ts-ignore
import scaleIcon from '../images/scale.svg';
// @ts-ignore
import muscleIcon from '../images/muscle.svg';
// @ts-ignore
import healthIcon from '../images/health.svg';
// @ts-ignore
import painIcon from '../images/knee-pain.svg';

const Achieve = () => (
	<Box component="section" position="relative" pt="28px" pb="40px" bgcolor={colors.backgroundLightGrey}>
		<Typography component="h2" variant="h2" mb="24px" color={colors.textBlack}>
			Das kannst du erreichen
		</Typography>

		<Grid container spacing={4} maxWidth={['unset', '800px', '800px', '1200px']} mx="auto" pr="32px" width="100%">
			<Grid item xs={12} sm={6} lg={3}>
				<Box
					height="100%"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					textAlign="center"
					borderRadius="16px">
					<Card elevation={2}>
						<CardContent>
							<img src={scaleIcon} alt="" loading="lazy" width="76" height="76" />
							<Typography
								component="h3"
								variant="h3"
								lineHeight={[1.5, 1.5, 1.5, 3]}
								color={colors.textBlack}
								my="24px">
								Fettabbau
							</Typography>
							<Typography component="p" color={colors.textGrey} fontFamily={FiraSans.book}>
								Verliere überschüssige Pfunde durch erhöhte Fettverbrennung.
							</Typography>
						</CardContent>
					</Card>
				</Box>
			</Grid>
			<Grid item xs={12} sm={6} lg={3}>
				<Box
					height="100%"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					textAlign="center"
					borderRadius="16px">
					<Card elevation={2}>
						<CardContent>
							<img src={muscleIcon} alt="" loading="lazy" width="76" height="76" />
							<Typography
								component="h3"
								variant="h3"
								lineHeight={[1.5, 1.5, 1.5, 3]}
								color={colors.textBlack}
								my="24px">
								Muskelaufbau
							</Typography>
							<Typography component="p" color={colors.textGrey} fontFamily={FiraSans.book}>
								Erlange eine starke und definierte Muskulatur.
							</Typography>
						</CardContent>
					</Card>
				</Box>
			</Grid>
			<Grid item xs={12} sm={6} lg={3}>
				<Box
					height="100%"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					textAlign="center"
					borderRadius="16px">
					<Card elevation={2}>
						<CardContent>
							<img src={healthIcon} alt="" loading="lazy" width="76" height="76" />
							<Typography component="h3" variant="h3" color={colors.textBlack} my="24px">
								Gesunder Körper
							</Typography>
							<Typography component="p" color={colors.textGrey} fontFamily={FiraSans.book}>
								Tue nachhaltig etwas für deinen Körper und deine Fitness und erhalte mehr Energie im
								Alltag.
							</Typography>
						</CardContent>
					</Card>
				</Box>
			</Grid>
			<Grid item xs={12} sm={6} lg={3}>
				<Box
					height="100%"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					textAlign="center"
					borderRadius="16px">
					<Card elevation={2}>
						<CardContent>
							<img src={painIcon} alt="" loading="lazy" width="76" height="76" />
							<Typography component="h3" variant="h3" color={colors.textBlack} my="24px">
								Schmerzen beseitigen
							</Typography>
							<Typography component="p" color={colors.textGrey} fontFamily={FiraSans.book}>
								Werde Rücken - oder Gelenkschmerzen los und trainiere effektiv trotz Arthrose.
							</Typography>
						</CardContent>
					</Card>
				</Box>
			</Grid>
		</Grid>
	</Box>
);

export default Achieve;
