import { Box, ThemeProvider, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import Header from '../components/header';
import Footer from '../components/footer';
import { mainTheme } from '../consts/themes';

import '../components/app.css';
import '../fonts/Fira_Sans/firaSans.css';
import '../fonts/Fjalla_One/fjalla.css';
// @ts-ignore
import ogImage from '../images/EasyFit6.jpg';
import CookieModal, { CookieModalProps } from '../components/cookieModal';
import { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { colors } from '../consts/colors';
// @ts-ignore
import content from '../content/impressum.json';
import DataComponent from '../components/dataComponent';

const title = 'Impressum | EASYFITNESS EMS Premium Club';
const description =
	'Werde Teil der Green-Family und trainiere mit Personaltrainer im exclusiven EASYFITNESS EMS Premium Club. Mit Minimalaufwand zur Wunschfigur und einem Leben ohne Beschwerden!';

const impressum = () => {
	const [areCookiesAccepted, setAreCookiesAccepted] = useState(true);

	const cookieModalRef = useRef<CookieModalProps>(null);

	useEffect(() => {
		setTimeout(() => {
			setAreCookiesAccepted(Cookies.get('gatsby-gdpr-facebook-pixel') === 'true' ? true : false);
			cookieModalRef.current?.openDialog();
		}, 1000);
	}, []);

	return (
		<ThemeProvider theme={mainTheme}>
			<Helmet htmlAttributes={{ lang: 'de' }}>
				<title>{title}</title>
				<meta property="og:title" content={title} />
				<meta property="og:type" content="website" />
				<meta name="description" property="og:description" content={description} />
				<meta property="og:image" content={ogImage} />
				<meta property="og:image:width" content="1280" />
				<meta property="og:image:height" content="721" />
				<meta name="facebook-domain-verification" content="u8yg6445br7e8s4ze31aszjzih2nay" />
			</Helmet>
			<Header logoLink={true} />
			<main>
				<Box bgcolor={colors.gray}>
					<Box
						color={colors.white}
						maxWidth="880px"
						minHeight="calc(100vh - 13.1em)"
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="left"
						textAlign="left"
						mx="auto"
						px="48px"
						py="32px"
						paddingTop="48px"
						paddingBottom="96px"
						boxSizing="border-box">
						<DataComponent data={content} />
					</Box>
				</Box>
			</main>
			<Footer />
			<CookieModal
				ref={cookieModalRef}
				areCookiesAccepted={areCookiesAccepted}
				setAreCookiesAccepted={setAreCookiesAccepted}
			/>
		</ThemeProvider>
	);
};

export default impressum;
