import { Typography, css } from '@mui/material';
import FiraSans from '../fonts/Fira_Sans/firaSans';

export interface ChildrenItem {
	content: string;
}

export interface DataItem {
	element: string;
	content: string;
	children?: ChildrenItem[];
}

interface TextData {
	elements: DataItem[];
}

interface Props {
	data: TextData;
}

export default function DataComponent({ data }: Props) {
	return (
		<>
			{data.elements.map((item: DataItem, index: number) => {
				switch (item.element) {
					case 'h1':
						return (
							<Typography key={index} component="h1" variant="h1" mb="36px" textAlign="left">
								{item.content}
							</Typography>
						);
					case 'h3':
						return (
							<Typography key={index} component="h3" variant="h3" my="24px" textAlign="left">
								{item.content}
							</Typography>
						);

					case 'h4':
						return (
							<Typography key={index} component="h4" variant="h4" my="22px" textAlign="left">
								{item.content}
							</Typography>
						);

					case 'p':
						return (
							<Typography
								key={index}
								component="p"
								fontFamily={FiraSans.regular}
								mb="10px"
								dangerouslySetInnerHTML={{ __html: item.content }}
							/>
						);
					case 'ul':
						return (
							<ul key={index} css={listStyle}>
								{item.children?.map((item: ChildrenItem, index: number) => {
									return <li key={index} dangerouslySetInnerHTML={{ __html: item.content }} />;
								})}
							</ul>
						);
					default:
						return;
				}
			})}
		</>
	);
}

// @ts-ignore
const listStyle = css({
	paddingLeft: '1em',
	'& li': {
		listStyleType: 'none',
		margin: '0.5em 0',
		fontFamily: FiraSans.regular,
	},
	'& li:before': {
		content: "'– '",
		position: 'absolute',
		transform: 'translate(-1em)',
	},
});
