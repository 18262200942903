import { Box, ThemeProvider } from '@mui/material';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import Header from '../components/header';
import { mainTheme } from '../consts/themes';
import '../components/app.css';
import '../fonts/Fira_Sans/firaSans.css';
import '../fonts/Fjalla_One/fjalla.css';
import RequestPlaceLanding from '../components/requestPlaceLanding';
import RequestPlaceSteps from '../components/requestPlaceSteps';
import { useCallback, useEffect, useRef, useState } from 'react';
// @ts-ignore
import ogImage from '../images/EasyFit6.jpg';
import CookieModal, { CookieModalProps } from '../components/cookieModal';
import Cookies from 'js-cookie';

const title = 'Platz anfragen | EASYFITNESS EMS Premium Club';
const description =
	'Werde Teil der Green-Family und trainiere mit Personaltrainer im exclusiven EASYFITNESS EMS Premium Club. Mit Minimalaufwand zur Wunschfigur und einem Leben ohne Beschwerden!';

const RequestPlace = () => {
	const [areCookiesAccepted, setAreCookiesAccepted] = useState(true);
	const [showSteps, setShowSteps] = useState(false);

	const cookieModalRef = useRef<CookieModalProps>(null);

	useEffect(() => {
		setTimeout(() => {
			setAreCookiesAccepted(Cookies.get('gatsby-gdpr-facebook-pixel') === 'true' ? true : false);
			cookieModalRef.current?.openDialog();
		}, 1000);
	}, []);

	const handleClick = useCallback(() => {
		setShowSteps(true);
	}, []);

	return (
		<ThemeProvider theme={mainTheme}>
			<Helmet htmlAttributes={{ lang: 'de' }}>
				<title>{title}</title>
				<meta property="og:title" content={title} />
				<meta property="og:type" content="website" />
				<meta name="description" property="og:description" content={description} />
				<meta property="og:image" content={ogImage} />
				<meta property="og:image:width" content="1280" />
				<meta property="og:image:height" content="721" />
				<meta name="facebook-domain-verification" content="u8yg6445br7e8s4ze31aszjzih2nay" />
			</Helmet>
			<Box minHeight="100vh" display="flex" flexDirection="column" justifyContent="space-between">
				<Header logoLink={true} />
				<Box component="main" width={['auto', '560px', '640px']} mx="auto" px="16px" mt="40px" mb="auto">
					<RequestPlaceLanding hide={showSteps} handleClick={handleClick} />
					<RequestPlaceSteps show={showSteps} />
				</Box>
				<Footer />
			</Box>
			<CookieModal
				ref={cookieModalRef}
				areCookiesAccepted={areCookiesAccepted}
				setAreCookiesAccepted={setAreCookiesAccepted}
			/>
		</ThemeProvider>
	);
};

export default RequestPlace;
