import { Box, ThemeProvider, Typography } from '@mui/material';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';
import Header from '../components/header';
import { colors } from '../consts/colors';
import { mainTheme } from '../consts/themes';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import '../components/app.css';
import '../fonts/Fira_Sans/firaSans.css';
import '../fonts/Fjalla_One/fjalla.css';
// @ts-ignore
import ogImage from '../images/EasyFit6.jpg';
import { useEffect, useRef, useState } from 'react';
import CookieModal, { CookieModalProps } from '../components/cookieModal';
import Cookies from 'js-cookie';

const title = '404: Nicht Gefunden | EASYFITNESS EMS Premium Club';
const description =
	'Werde Teil der Green-Family und trainiere mit Personaltrainer im exclusiven EASYFITNESS EMS Premium Club. Mit Minimalaufwand zur Wunschfigur und einem Leben ohne Beschwerden!';

const NotFoundPage = () => {
	const [areCookiesAccepted, setAreCookiesAccepted] = useState(true);

	const cookieModalRef = useRef<CookieModalProps>(null);

	useEffect(() => {
		setTimeout(() => {
			setAreCookiesAccepted(Cookies.get('gatsby-gdpr-facebook-pixel') === 'true' ? true : false);
			cookieModalRef.current?.openDialog();
		}, 1000);
	}, []);

	return (
		<ThemeProvider theme={mainTheme}>
			<Helmet htmlAttributes={{ lang: 'de' }}>
				<title>{title}</title>
				<meta property="og:title" content={title} />
				<meta property="og:type" content="website" />
				<meta name="description" property="og:description" content={description} />
				<meta property="og:image" content={ogImage} />
				<meta property="og:image:width" content="1280" />
				<meta property="og:image:height" content="721" />
				<meta name="facebook-domain-verification" content="u8yg6445br7e8s4ze31aszjzih2nay" />
			</Helmet>
			<Box minHeight="100vh" display="flex" flexDirection="column" justifyContent="space-between">
				<Header logoLink={true} />
				<Box component="main" textAlign="center">
					<Typography component="h1" variant="h1">
						404: Nicht Gefunden
					</Typography>
					<Typography
						component="p"
						fontFamily={FiraSans.regular}
						fontSize={['1rem', '1.25rem']}
						color={colors.textGrey}
						textAlign="center">
						Sie haben gerade eine Route getroffen, die es nicht gibt
					</Typography>

					{/* @ts-ignore */}
					<Link to="/" replace css={buttonStyle}>
						Startseite
					</Link>
				</Box>
				<Footer />
			</Box>
			<CookieModal
				ref={cookieModalRef}
				areCookiesAccepted={areCookiesAccepted}
				setAreCookiesAccepted={setAreCookiesAccepted}
			/>
		</ThemeProvider>
	);
};

export default NotFoundPage;

const buttonStyle = css({
	display: 'inline-block',
	color: colors.textBlack,
	backgroundColor: colors.limeGreen,
	padding: '14px 34px',
	borderRadius: '8px',
	fontFamily: FiraSans.medium,
	fontSize: '1rem',
	lineHeight: 1.5,
	marginTop: '32px',
});
