import { createTheme } from '@mui/material/styles';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import Fjalla from '../fonts/Fjalla_One/fjalla';
import { colors } from './colors';

export const mainTheme = createTheme({
	palette: {
		primary: {
			main: colors.limeGreen,
		},
		error: {
			main: colors.errorRed,
		},
	},
	components: {
		MuiCard: {
			styleOverrides: {
				root: ({ theme }) => ({
					borderRadius: '16px',
					padding: '24px',
					height: '100%',
					backgroundColor: 'white',
					[theme.breakpoints.down('md')]: {
						width: 'calc(100% - 16px)',
					},
				}),
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					boxSizing: 'border-box',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
				},
				containedPrimary: {
					display: 'inline-block',
					color: colors.textBlack,
					backgroundColor: colors.limeGreen,
					padding: '14px 34px',
					borderRadius: '8px',
					fontFamily: FiraSans.medium,
					fontSize: '1rem',
					lineHeight: 1.5,
					'&:hover': {
						backgroundColor: colors.limeGreen,
					},
				},
				textPrimary: {
					fontFamily: FiraSans.regular,
					fontSize: '1rem',
					lineHeight: 1.5,
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					'&>.MuiRadio-root.Mui-checked+span': {
						color: colors.limeGreen,
					},
				},
				label: {
					fontFamily: FiraSans.medium,
					color: colors.textGrey,
				},
			},
		},
		MuiMobileStepper: {
			styleOverrides: {
				root: {
					position: 'static',
					fontFamily: FiraSans.medium,
					fontSize: '1rem',
					lineHeight: 1.5,
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					boxSizing: 'border-box',
				},
				progress: ({ theme }) => ({
					width: '400px',
					[theme.breakpoints.down('sm')]: {
						width: '200px',
					},
				}),
			},
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					fontFamily: FiraSans.regular,
					color: colors.textGrey,
					fontSize: '1rem',
					lineHeight: 1.5,
					'&::placeholder': {
						opacity: 0.7,
					},
				},
			},
		},
		MuiSlider: {
			styleOverrides: {
				valueLabel: {
					backgroundColor: colors.limeGreen,
					fontFamily: FiraSans.medium,
					fontSize: '1rem',
					lineHeight: 1.5,
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				filledError: {
					fontFamily: FiraSans.regular,
					fontSize: '1rem',
					lineHeight: 1.5,
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					padding: '1em 1.6em',
					fontFamily: Fjalla.regular,
					fontSize: '1.25rem',
					lineHeight: '1.6em',
					color: colors.textBlack,
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					padding: '1em 2em',
					fontFamily: FiraSans.regular,
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					padding: '1em 2em',
					alignItems: 'flex-end',
					'@media (max-width: 649px)': {
						flexDirection: 'column',
					},
					'&>*:not(:last-child)': {
						'@media (max-width: 649px)': {
							marginBottom: '0.66666667em',
						},
					},
				},
				spacing: {
					'&>:not(:first-child)': {
						marginLeft: '1rem',
						'@media (max-width: 649px)': {
							marginLeft: 0,
						},
					},
				},
			},
		},
	},
});

mainTheme.typography.h1 = {
	fontFamily: Fjalla.regular,
	fontSize: '2rem',
	lineHeight: 1.5,
	textAlign: 'center',
	[mainTheme.breakpoints.up('sm')]: {
		fontSize: '2.5rem',
	},
};

mainTheme.typography.h2 = {
	fontFamily: Fjalla.regular,
	fontSize: '2rem',
	lineHeight: 1.5,
	textAlign: 'center',
	[mainTheme.breakpoints.up('sm')]: {
		fontSize: '2.5rem',
	},
};

mainTheme.typography.h3 = {
	fontFamily: Fjalla.regular,
	fontSize: '1.5rem',
	lineHeight: 1.5,
	textAlign: 'center',
	[mainTheme.breakpoints.up('sm')]: {
		fontSize: '2rem',
	},
};

mainTheme.typography.h4 = {
	fontFamily: Fjalla.regular,
	fontSize: '1.25rem',
	lineHeight: 1.5,
	textAlign: 'center',
	[mainTheme.breakpoints.up('sm')]: {
		fontSize: '1.5rem',
	},
};

mainTheme.typography.h5 = {
	fontFamily: Fjalla.regular,
	fontSize: '1rem',
	lineHeight: 1.5,
	textAlign: 'center',
	[mainTheme.breakpoints.up('sm')]: {
		fontSize: '1.25rem',
	},
};
